import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Union Dex',
  defaultTitle: 'Union Dex',
  description:
    'The most trusted decenteralized exchange platform powered by 0xFactory.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Union DexDex',
    site: '@Union DexDex',
  },
  openGraph: {
    title: 'Union Dex - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'The most trusted decenteralized exchange platform powered by 0xFactory.',
    images: [{ url: 'https://0xfactory.com/logo.png' }],
  },
}
